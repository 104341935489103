import React, { useContext } from 'react'
import Head from 'next/head'
import { Typography } from '@mui/material'
import AuthContext from '../context/AuthContext'
import moment from 'moment-timezone'

export default function Home() {
  const { user } = useContext(AuthContext)
  // let dt1 = moment(new Date()).tz(user.timezone).format();
  // let dt2 = moment(new Date()).tz(`${user.timezone}`).format();
  return (
    <>
      <Head>
        <title>Backstage Dashboard</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {/* <Typography component="div">
        <Typography variant="h6">ISO Code : {user.iso_code}</Typography>
        <Typography variant="h6">Currency : {user.currency_code}</Typography>
        <Typography variant="h6">Phone Code : {user.phone_code}</Typography>
        <Typography variant="h6">Timezone : {user.timezone}</Typography>
        <Typography variant="h6">GMT Offset : {user.gmt_offset}</Typography>
        <Typography variant="h6">GMT Offset Code : {user.gmt_abbreviation}</Typography>
        {dt1}<br/>
        {dt2}
      </Typography> */}
    </>
  )
}
